var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"min-h-[calc(100vh-40px)] mx-5 my-[1.5%] bg-white dark:bg-gray-800 rounded-2xl shadow-lg p-5"},[_c('header',{staticClass:"mb-6"},[_c('div',{staticClass:"flex items-center"},[_c('h2',{staticClass:"text-2xl font-semibold text-gray-800 dark:text-gray-200 mr-6"},[_vm._v("免费号池")]),_c('el-switch',{attrs:{"active-color":"#0e8f6f","active-text":"Claude","inactive-text":"ChatGPT"},on:{"change":_vm.toggleAIType},model:{value:(_vm.showClaude),callback:function ($$v) {_vm.showClaude=$$v},expression:"showClaude"}})],1)]),_c('main',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.pageLoading),expression:"pageLoading"}],staticClass:"relative",attrs:{"element-loading-text":"加载中...","element-loading-spinner":"el-icon-loading"}},[(_vm.pageLoading)?_c('el-skeleton',{attrs:{"loading":_vm.pageLoading,"animated":"","count":4}},[_c('template',{slot:"template"},[_c('div',{staticClass:"grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-5"},[_c('div',{staticClass:"pb-4"},[_c('div',{staticClass:"h-[150px] rounded-lg bg-gray-200 dark:bg-gray-700"})])])])],2):_vm._e(),(!_vm.isMobile)?_c('div',{staticClass:"pc-view"},[(!_vm.pageLoading)?_c('div',{staticClass:"grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-5 px-2.5"},_vm._l((_vm.filteredAccounts),function(account,index){return _c('div',{key:account.id,staticClass:"min-w-0"},[_c('el-card',{class:[
              'transition-all duration-300 hover:-translate-y-0.5 border-none shadow-md dark:!bg-gray-700/50 dark:hover:!bg-gray-700/70',
              { 'opacity-80 bg-gray-50 dark:bg-gray-800/70': account.sessionToken === '1' }
            ],attrs:{"shadow":"hover"}},[_c('div',{staticClass:"flex justify-between items-center",attrs:{"slot":"header"},slot:"header"},[_c('span',{staticClass:"font-medium text-gray-700 dark:text-gray-200"},[_vm._v(_vm._s(account.type)+"账号 "+_vm._s(index + 1))]),_c('el-tag',{staticClass:"font-medium",attrs:{"type":account.sessionToken === '' ? 'success' : 'danger',"size":"small","effect":"dark"}},[_vm._v(" "+_vm._s(account.sessionToken != '' ? "繁忙" : "空闲")+" ")])],1),_c('el-button',{staticClass:"!w-full mt-2.5 font-medium !bg-gradient-to-r !from-emerald-600 !to-emerald-500 hover:!from-emerald-500 hover:!to-emerald-400 disabled:!opacity-70 disabled:!from-gray-600 disabled:!to-gray-500 dark:!from-emerald-500 dark:!to-emerald-400 dark:hover:!from-emerald-400 dark:hover:!to-emerald-300 !transition-all !duration-300 hover:!-translate-y-0.5 !shadow-emerald-200/20 dark:!shadow-emerald-900/30",attrs:{"type":"primary","disabled":account.sessionToken != '',"loading":_vm.loadingAccounts[account.id],"element-loading-spinner":"el-icon-loading"},on:{"click":function($event){return _vm.useAccount(account.id)}}},[_vm._v(" "+_vm._s(account.sessionToken != '' ? '暂不可用' : (_vm.loadingAccounts[account.id] ? '跳转中...' : '立即使用'))+" ")])],1)],1)}),0):_vm._e()]):_c('div',{staticClass:"mobile-view"},[(!_vm.pageLoading)?_c('div',{staticClass:"space-y-4 px-2.5"},_vm._l((_vm.filteredAccounts),function(account,index){return _c('div',{key:account.id,class:[
            'p-4 bg-white dark:bg-gray-700/50 rounded-xl shadow-md transition-all duration-300 hover:shadow-lg dark:hover:bg-gray-700/70',
            { 'opacity-80 bg-gray-50 dark:bg-gray-800/70': account.sessionToken === '1' }
          ]},[_c('div',{staticClass:"mb-4"},[_c('div',{staticClass:"flex justify-between items-center"},[_c('span',{staticClass:"text-base font-medium text-gray-700 dark:text-gray-200"},[_vm._v(_vm._s(account.type)+"账号 "+_vm._s(index + 1))]),_c('el-tag',{attrs:{"type":account.sessionToken === '' ? 'success' : 'danger',"size":"mini","effect":"dark"}},[_vm._v(" "+_vm._s(account.sessionToken != '' ? "繁忙" : "空闲")+" ")])],1)]),_c('el-button',{staticClass:"!w-full !h-10 !text-base !font-medium",attrs:{"type":"success","disabled":account.sessionToken != '',"loading":_vm.loadingAccounts[account.id],"size":"medium"},on:{"click":function($event){return _vm.useAccount(account.id)}}},[_vm._v(" "+_vm._s(account.sessionToken != '' ? '暂不可用' : (_vm.loadingAccounts[account.id] ? '跳转中...' : '立即使用'))+" ")])],1)}),0):_vm._e()]),(!_vm.pageLoading && _vm.filteredAccounts.length === 0)?_c('div',{staticClass:"text-center py-10 text-gray-400"},[_c('i',{staticClass:"el-icon-warning-outline text-4xl mb-4"}),_c('p',{staticClass:"text-base m-0"},[_vm._v("暂无可用账号")])]):_vm._e()],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }